import React, { useEffect } from 'react'
import { any } from 'prop-types'

// components
import Auth from '@modules/auth'
import Http from '@modules/http'
import Url from '@modules/url'
import Cookie from '@modules/cookie'
import { LAMBDA_HEALTH_CHECK, IMPERSONATION_COOKIE_NAME } from '@modules/constants'

const MAX_WAIT_MILISECONDS_HEALTH_CHECK = 2000

const LoginPage = ({ location }) => {
  const queryString = Url.parseSearch(location.search || {})
  const { ref } = queryString || {}
  useEffect(() => {
    Cookie.remove([IMPERSONATION_COOKIE_NAME], {}, () => null)
    const version = 1
    const baseApiUrl =
      process.env[`GATSBY_PC_API_URL_V${version}`] ||
      process.env['GATSBY_PC_API_URL']
    const apiUrl = baseApiUrl.toString().replace('/v1', '')
    const tmrWaitHealthCheck = setTimeout(
      () => Auth.login(),
      MAX_WAIT_MILISECONDS_HEALTH_CHECK
    )
    Http.post(
      {
        url: apiUrl + LAMBDA_HEALTH_CHECK
      },
      () => {
        clearTimeout(tmrWaitHealthCheck)
        Auth.login()
      }
    )
  }, [])

  return (
    <div className="pa-4">
      {ref === 'logout'
        ? 'Redirecting...'
        : 'Your first login may take several minutes while your data is migrated. Thank you for your patience...'}
    </div>
  )
}

LoginPage.propTypes = {
  location: any
}

LoginPage.defaultProps = {
  location: {}
}

export default LoginPage
